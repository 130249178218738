<template>
    <section class="users container-fluid d-flex flex-column h-100 py-3 pl-0">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 d-flex flex-column pl-4">
                <div class="title d-flex align-items-center pb-1">
                    <div class="d-flex d-xl-none align-items-center">
                        <button-toggle-side-bar></button-toggle-side-bar>
                    </div>
                    <div class="flex-grow-1">
                        <h1 class="text-dark font-weight-bolder col pl-xl-0 mb-0">
                            Users
                        </h1>
                    </div>
                    <div class="d-flex align-items-center">
                        <b-form-select class="shadow-sm mr-2"
                                       v-model="statusFilter"
                                       v-on:input="onStatusInput">
                            <option v-bind:value="null">
                                All
                            </option>
                            <option value="pending">
                                Pending
                            </option>
                            <option value="pending_verification">
                                Not Verified
                            </option>
                            <option value="approved">
                                Approved
                            </option>
                        </b-form-select>
                        <button class="btn btn-light border text-nowrap shadow-sm"
                                v-on:click="isAddUserModalOpened = true">
                            ✨ New User
                        </button>
                    </div>
                </div>

                <div class="user-list-heading pb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <router-link class="nav-link d-flex align-items-center"
                                                 active-class="active"
                                                 to="/users/user">
                                        <span class="mr-2">Users</span>
                                        <span class="badge badge-light border">
                                            {{ users.length }}
                                        </span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link d-flex align-items-center"
                                                 active-class="active"
                                                 to="/users/admin">
                                        <span class="mr-2">Administrators</span>
                                        <span class="badge badge-light border">
                                            {{ admins.length }}
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <search-bar class="search-bar"
                                    has-filter
                                    v-model.trim="keyword"
                                    v-on:input="onSearchInput">
                        </search-bar>
                    </div>
                </div>
                <router-view name="Users"></router-view>
            </div>

            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view></router-view>
            </div>
        </div>

        <modal-user v-if="userRole === 'admin'"
                    v-model="isAddUserModalOpened">
        </modal-user>
    </section>
</template>

<script>
import { BFormSelect, } from "bootstrap-vue";
export default {
    name: "Users",
    components: {
        BFormSelect,
        "ButtonToggleSideBar": () => import("../components/layout/ButtonToggleSideBar"),
        "SearchBar": () => import("../components/layout/SearchBar"),
        "ModalUser": () => import("../components/Users/ModalUser"),
    },
    data () {
        return {
            isAddUserModalOpened: false,

            statusFilter: null,
            keyword: "",
            verifiedFilter: false,
            inRequiredGroupsFilter: false,
        };
    },
    computed: {
        userRole () {
            return this.$store.getters["admin/info"]?.role ?? "";
        },
        admins () {
            return this.$store.getters["users/itemsByRole"]("admin") ?? [];
        },
        organizers () {
            return this.$store.getters["users/itemsByRole"]("organizer") ?? [];
        },
        users () {
            return this.$store.getters["users/itemsByRole"]("user") ?? [];
        },
    },
    methods: {
        onSearchInput (evt) {
            this.$router.push({
                "path": this.$route.path,
                "query": {
                    ...this.$route.query,
                    "keyword": evt || undefined,
                },
            });
        },
        onStatusInput (evt) {
            this.$router.push({
                "path": this.$route.path,
                "query": {
                    ...this.$route.query,
                    "status": evt || undefined,
                },
            });
        },
    },
    mounted () {
        this.$store.dispatch("users/refreshUsersRegularly");
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch("users/stopRefreshingUsers");
        next();
    },
}
</script>

<style lang="scss" scoped>
.user-list-heading {
    z-index: 99;
}
</style>
